/*
 * PROGRAM
 */

.c-program {
    position: relative;
}

.c-program-timeline {
    position: absolute;
    background: $grey;
    display: block;
    height: 100%;
    left: 50%;
    margin-left: -2px;
    margin-top: 0;
    top: 0;
    width: 4px;

    @include media-query(md) {
        border-radius: 100px;
        height: 4px;
        width: 100%;
        margin-top: -2px;
        left: 0;
        top: 50%;
    }
}

/*
 * Items
 */

.c-program-items {
    width: 100%;
    position: relative;
    z-index: 10;

    &:after {
        background: $primary;
        content: '';
        position: absolute;
        height: 100%;
        margin-top: 0;
        left: 50%;
        margin-left: -2px;
        top: 0;
        width: 4px;

        @include media-query(md) {
            border-radius: 100px;
            height: 4px;
            left: 0;
            margin-top: -2px;
            position: absolute;
            top: 50%;
            width: 100%;
        }
    }
}

.c-program-items-bottom {
    width: auto;
}

/*
 * Item
 */

.c-program-item {
    position: relative;
    text-align: center;
    z-index: 15;

    @include first() {

        &:after {
            background: $grey;
            border-radius: 100px;
            content: '';
            position: absolute;
            z-index: -1;
            height: 50%;
            margin-top: 0;
            margin-left: -2px;
            width: 4px;
            right: 50%;
            top: 0;

            @include media-query(md) {
                height: 4px;
                margin-top: -2px;
                margin-right: 0;
                top: 50%;
                width: 50%;
                left: 0;
            }
        }
    }

    @include last() {

        &:after {
            background: $grey;
            border-radius: 100px;
            content: '';
            position: absolute;
            z-index: -1;
            height: 50%;
            margin-top: 0;
            margin-right: -2px;
            width: 4px;
            right: 50%;
            bottom: 0;

            @include media-query(md) {
                height: 4px;
                margin-top: -2px;
                top: 50%;
                width: 50%;
                left: 50%;
            }
        }
    }
}

.c-program-item-align-self {
    align-self: center;
}

.c-program-item-break {

    .c-program-item-circles {
        position: relative;
        width: auto;

        .c-program-item-circle {

            &:after {
                content: '';
                background: $grey;
                position: absolute;
                z-index: -1;
                height: 50%;
                left: 50%;
                margin-left: -2px;
                margin-top: 0;
                top: 25%;
                width: 4px;

                @include media-query(md) {
                    margin-top: -2px;
                    top: 50%;
                    left: 25%;
                    width: 50%;
                    z-index: -1;
                    height: 4px;
                }
            }
        }
    }
}

.c-program-item-small {

    .c-program-item-top {
        margin: 0 1rem;
    }

    .c-program-item-circles {
        position: relative;
        width: auto;

        .c-program-item-circle {
            margin: 2px;
            height: 8px;
            width: 8px;

            &:after {
                content: '';
                background: $grey;
                position: absolute;
                z-index: -1;
                height: 50%;
                left: 50%;
                margin-left: -2px;
                margin-top: 0;
                top: 25%;
                width: 4px;

                @include media-query(md) {
                    margin-top: -2px;
                    height: 4px;
                    top: 50%;
                    left: 25%;
                    width: 50%;
                    z-index: -1;
                }
            }
        }
    }
}

.c-program-item-top {
    width: 150px;

    @include media-query(md) {
        width: auto;
    }
}

.c-program-item-circle {
    background: $white;
    border: 1px solid $medium;
    border-radius: 100px;
    height: 12px;
    width: 12px;
}

.c-program-item-bottom {
    width: 150px;

    @include media-query(md) {
        width: auto;
    }
}
