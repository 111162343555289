/*
 * BUTTONS
 */

.c-button {
    display: inline-block;
    padding: .65rem 1.25rem;
    background-color: transparent;
    border-radius: $border-radius;
    transition: all .1s ease;
    font-weight: bold;
    color: $dark;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Din', Helvetica, sans-serif;
    border: 1px solid $dark;
    transition: background-color 333ms ease;
    font-size: .85rem;

    &:hover {
        background-color: $dark;
        color: $white;
        text-decoration: none;
    }
}

.c-button-info {
    background-color: $info;
    color: $white;

    &:hover {
        background-color: lighten($info, 5%);
        color: $white;
    }
}

.c-button-success {
    background-color: $success;
    color: #fff;

    &:hover {
        background-color: lighten($success, 5%);
        color: $white;
    }
}

.c-button-warning {
    background-color: $warning;
    color: $white;

    &:hover {
        background-color: lighten($warning, 5%);
        color: $white;
    }
}

.c-button-error {
    background-color: $error;
    color: $white;

    &:hover {
        background-color: lighten($error, 5%);
        color: $white;
    }
}

.c-button-primary {
    background-color: $primary;
    color: $white;

    &:hover {
        background-color: lighten($primary, 5%);
        color: $white;
    }
}

.c-button-secondary {
    background-color: $secondary;
    color: $white;

    &:hover {
        background-color: lighten($secondary, 5%);
        color: $white;
    }
}

.c-button-tertiary {
    border: 1px solid $tertiary;
    background-color: transparent;
    color: $tertiary;

    &:hover {
        background-color: $tertiary;
        color: $white;
    }
}

// Links

.c-link-chevron {
    font-weight: 700;
}

.c-link-chevron::after {
    border-style: solid;
    border-width: 0.085em 0.085em 0 0;
    content: '';
    display: inline-block;
    height: .5em;
    left: .5em;
    position: relative;
    top: .6em;
    transform: rotate(45deg);
    transition: all .15s ease;
    vertical-align: top;
    width: .5em;
}
