/*
 * NAV
 */

// Main Menu

 .c-nav-main {
    width: 100%;
    color: $grey;
    z-index: 99;

    .c-nav-main-logo-white {
        display: none;
    }
}
/*
body.practical,
body.travellers {

    .c-nav-main-logo-color {
        display: none;
    }
    .c-nav-main-logo-white {
        display: block;
    }

    .c-nav-menu-wrapper {

        .c-nav-menu a {

            @include media-query(lg) {
                color: $white;

                &:hover {
                    color: $primary;
                }
            }
        }
        .c-nav-menu > .c-nav-menu-item-active {

            a {
                color: $primary;
            }
        }
    }

    .c-sticky-top {
        background-color: transparent !important;
    }

    .c-sticky-top.isFixed {

        background-color: $white !important;

        .c-nav-main-logo-color {
            display: block;
        }
        .c-nav-main-logo-white {
            display: none;
        }

        .c-nav-menu-wrapper {

            .c-nav-menu a {

                @include media-query(lg) {
                    color: $grey;

                    &:hover {
                        color: $primary;
                    }
                }
            }
            .c-nav-menu > .c-nav-menu-item-active {

                a {
                    color: $primary;
                }
            }
        }
    }
}
*/

.c-nav-main-container {
    position: relative;

    @include media-query(md) {
        margin: auto;
        max-width: getBreakPoint(md);
        padding: 0 2.5rem;
    }

    @include media-query(lg) {
        margin: auto;
        max-width: getBreakPoint(lg);
        padding: 0 2.5rem;
    }

    @include media-query(xl) {
        margin: auto;
        max-width: getBreakPoint(xl);
        padding: 0 2.5rem;
    }
}

.c-nav-main-logo {
    position: relative;

    img {
        height: 35px;

        @include media-query(lg) {
            height: 50px;
        }
    }
}

.c-nav-social {
    position: fixed;
    z-index: 999;
    right: 0;
    bottom: 0;
    width: 80px;

    svg.c-icon {
        transition: fill 333ms ease;
        width: 20px;
        height: 20px;
    }

    .c-nav-social-facebook svg.c-icon{
        &:hover {
            fill: $facebook;
        }
    }

    .c-nav-social-instagram svg.c-icon{
        &:hover {
            fill: $instagram;
        }
    }

    .c-nav-social-linkedin svg.c-icon{
        &:hover {
            fill: $linkedin;
        }
    }

    .c-nav-social-twitter svg.c-icon{
        &:hover {
            fill: $twitter;
        }
    }

    .c-nav-social-behance svg.c-icon{
        &:hover {
            fill: $behance;
        }
    }
}

// https://codepen.io/stephaniewalter/pen/kficJ
.c-nav-menu-wrapper {
    z-index: 10;
    text-transform: uppercase;
    background: $white;
    display: block;
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    max-height: 100vh;
    overflow-y: scroll;

    @include media-query(lg) {
        width: auto;
        position: relative;
        display: flex;
        top: 0;
        left: 0;
        overflow-y: visible;
        max-height: none !important;
        background: transparent;
    }

    .c-nav-menu {
        margin: 0;
        padding-left: 0;
        list-style: none;
        text-align: center;
        border-top: 1px solid $light;

        @include media-query(lg) {
            display: flex;
            justify-content: center;
            border-top: none;
        }
    }

    .c-nav-menu li {
        position: relative;
        margin: 0;
        padding: 0;
    }

    .c-nav-menu > li {
        padding: 0;
        margin: 0;

        &+li {
            border-top: 1px solid $light;
        }

        @include media-query(lg) {
            position: relative;
            display: block;
            padding: 0;

            &+li {
                border-top: none;
            }
        }
    }

    .c-nav-menu a {
        display: block;
        padding: .75em 0;
        margin: 0;
        border: none;
        font-family: 'DinMedium';
        font-size: .85rem;

        &:hover {
            color: $primary;
        }

        @include media-query(lg) {
            padding: 2em .75em;
        }
    }

    /* the hidden link for RWD nav */
    a.menu-link {
        display: block;
        width: auto;
        padding-right: 15px;
        padding-left: 10px;
        border: none;
        margin: 0;

        &:hover {
            background: darken($primary, 15%);
        }

        @include media-query(md) {
            display: none;
        }
    }

    /* the rest of the menu lvl 1 */
    a {
        display: block;
        position: relative;
        padding: 0;
        color: $grey;
        text-decoration: none;

        &:hover {
            text-decoration: none;
            color: $grey;
            transition: all .5s;
        }
    }

    /* current links */
    .c-nav-menu > .c-nav-menu-item-active,
    .c-nav-menu > .c-nav-menu-item-activeancestor {
        position: relative;
        z-index: 12;
        // background: $secondary;

        a {
            color: $yellow;

            /*
            &:hover {
                background-color: $secondary;
                color: $dark;
            }
            */
        }

        /*

        @include media-query(md) {
            &:after {
                content: '';
                display: block;
                width: 100%;
                height: 4px;
                background-color: $secondary;
                position: absolute;
                bottom: 0;
            }
        }

        */
    }

    /* submenu */
    .c-nav-menu-sub {
        display: block;
        background: none;
        margin-left: 0px;
        margin-top: 1px;
        box-sizing: border-box;
        position: absolute;
        max-height: 0em;
        overflow: hidden;
        list-style: none outside none;
        text-align:left;
        text-transform: none;
        width: 100%;

        li {
            display: block;
            background: darken($primary, 15%);
            border-bottom: 1px solid lighten($primary, 5%);
        }

        li:last-child {
            padding: 0;

            a {
                border: none;
            }
        }

        a {
            white-space: nowrap;
            padding-left: 1em;

            &:hover {
                background: darken($primary, 5%)
            }
        }

        @include media-query(lg) {
            margin: 0;
            padding: 0;
            width: auto;

            a {
                padding: 1.25em;
            }
        }
    }

    /* unhide the 2nd level */
    .c-nav-menu .c-menu-item-has-sub {
        position: relative;
    }

    li .c-nav-menu-sub {
        margin-bottom: 0;
    }

    li .c-nav-menu-sub.active {
        max-height: 30em;
        overflow: visible;
        position: relative;
        z-index: 9;
        transition: max-height .5s ease-out;
    }

    li:hover .c-nav-menu-sub {

        @include media-query(md) {
            max-height: 30em;
            transition: max-height .5s;
        }
    }

    /* styling the toggle menu */
    .toggle-link{
        cursor: pointer;
        width: 74px;
        height: 40px;
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 200;

        &:after {
            content: '';
            border: solid $light;
            border-width: 0 5px 5px 0;
            display: inline-block;
            padding: 5px;
            position: absolute;
            top: 50%;
            margin-top: -8px;
            right: 50%;
            margin-right: -5px;
            transition: all .2s;
            transform: rotate(45deg);
        }

        @include media-query(lg) {
            display: none;
        }
    }

    .toggle-link.active {

        &:after {
            transform: rotate(-135deg);
        }
    }
}

.c-nav-menu-wrapper nav[role=navigation] {
    overflow: hidden;
    max-height: 0;

    @include media-query(lg) {
        overflow: visible;
        max-height: none !important;
    }
}

.c-nav-menu-wrapper nav[role=navigation].active {
    max-height: 100vh;
    transition: max-height .5s ease-out;
}

// Footer Menu

.c-nav-menu-footer {
    list-style-type: none;
    margin: 0;

    li {

        a {
            text-decoration: none;
            font-size: .85rem;
            line-height: 1rem;
        }
    }
}

// Breadcrumbs Menu

.c-nav-menu-breadcrumbs {
    list-style-type: none;
    margin-left: 0;

    li {
        display: inline;
    }
}