/*
 * SIDEBAR
 */

.c-sidebar {
    background-color: $dark;
    color: $white;
    position: fixed;
    left: 0;
    transition: all 0.3s ease-out;
    top: 0;
    height: 100vh;
    overflow-y: scroll;
    z-index: 101;

    .is-pushed-right & {
        left: -320px;
    }

    a {
        &.active, &:hover {
            color: $secondary;
            transition: all 0.3s ease-out;
        }
    }
}
