/*
 * App
 */

#app {
    background-color: $white;
    position: relative;
}

.inverted {

  h1, h2, h3, h4, h5, h6, p, li, a, address {
      color: $white;
  }

  a {

      &:hover {
          color: $primary;
      }
  }
}

.u-full-width {
    width: 100%;
}

.u-full-height {
    height: 100%;
}

.u-full-height-md {

    @include media-query(md) {
        height: 100%;
    }
}

// Background Colors

.bg-yellow {
  background-color: $yellow;
}

.bg-green {
  background-color: $green;
}

.bg-white {
    background-color: $white;
}

.bg-light {
    background-color: $light;
}

// Text Colors

.txt-white {

    h1, h2, h3, h4, h5, h6, p , strong{
        color: $white !important;
    }
}

.txt-grey {

    h1, h2, h3, h4, h5, h6, p{
        color: $grey !important;
    }
}

.txt-green {

    h1, h2, h3, h4, h5, h6, p{
        color: $green !important;
    }
}

.txt-yellow {

    h1, h2, h3, h4, h5, h6, p{
        color: $yellow !important;
    }
}

// Text Other

.txt-uppercase {

    h1, h2 {
        text-transform: uppercase;;
    }
}

.img-height-auto {
    height: auto;
}