/*
 * SETTINGS
 */

// GRID
$columns: 12;
$gutter: 40/16*1rem;

$breakpoints: (
    xs: 480/16*1rem,    // 30 rem
    sm: 640/16*1rem,    // 40 rem
    md: 768/16*1rem,    // 48 rem
    lg: 1024/16*1rem,   // 64 rem
    xl: 1280/16*1rem,   // 80 rem
    xxl: 1536/16*1rem   // 96 rem
);

// SPACINGs
$spacing: (
    auto: auto,
    none: 0/16*1rem,
    xs: 8/16*1rem,      // 0.5 rem
    sm: 16/16*1rem,     // 1.0 rem
    md: 24/16*1rem,     // 1.5 rem
    lg: 32/16*1rem,     // 2.0 rem
    xl: 40/16*1rem,     // 2.5 rem
    xxl: 80/16*1rem     // 5 rem
);

// COLORS
$info: #000000;
$success: #4caf50;
$warning: #ffc107;
$error: #e70638;

$dark: #070E19;
$medium: #707070;
$grey: #656565;
$light: #F8F8F8;
$white: #ffffff;

$green: #004f56;            // rgba(0, 79, 86, 1)
$yellow: #ffa600;           // rgba(255, 166, 0, 1)

$primary: $yellow;
$secondary: $green;
$tertiary: $green;

// SOCIAL MEDIA
$linkedin: #0077b5;
$facebook: #3b5998;
$twitter: #1da1f2;
$instagram: #c13584;
$behance: #0057ff;

// BORDER RADIUS
$border-radius: 1px;

// TYPOGRAPHY
@include fontFace('DinLight', '../fonts/din/din-ligh-webfont');
@include fontFace('DinRegular', '../fonts/din/din-regu-webfont');
@include fontFace('DinMedium', '../fonts/din/din-medi-webfont');
@include fontFace('DinBold', '../fonts/din/din-bold-webfont');
@include fontFace('DinBlack', '../fonts/din/din-blac-webfont');
@import url("https://use.typekit.net/qry4fji.css");

$min: .85;

$base-font-family: 'Museo', Helvetica, sans-serif;
$base-font-size-min: 1rem * $min;
$base-font-size-max: 1rem;
$base-font-weight: 400;
$base-line-height: 1.5;
$base-color: $grey;

$h1-font-family: 'DinBlack', Helvetica, sans-serif;
$h1-font-size-min: 1.75rem * $min;
$h1-font-size-max: 2.25rem;
$h1-font-weight: 700;
$h1-line-height: 1.35;
$h1-color: $dark;

$h2-font-family: 'DinBlack', Helvetica, sans-serif;
$h2-font-size-min: 1.825rem * $min;
$h2-font-size-max: 1.825rem;
$h2-font-weight: 700;
$h2-line-height: 1.5;
$h2-color: $dark;

$h3-font-family: 'DinBlack', Helvetica, sans-serif;
$h3-font-size-min: 1.625rem * $min;
$h3-font-size-max: 1.635rem;
$h3-font-weight: 700;
$h3-line-height: 1.5;
$h3-color: $dark;

$h4-font-family: $base-font-family;
$h4-font-size-min: 1.425rem * $min;
$h4-font-size-max: 1.425rem;
$h4-font-weight: 500;
$h4-line-height: 1.35;
$h4-color: $dark;

$h5-font-family: $base-font-family;
$h5-font-size-min: 1.25rem * $min;
$h5-font-size-max: 1.25rem;
$h5-font-weight: 500;
$h5-line-height: 1.35;
$h5-color: $dark;

$h6-font-family: $base-font-family;
$h6-font-size-min: 1.15rem * $min;
$h6-font-size-max: 1.15rem;
$h6-font-weight: 500;
$h6-line-height: 1.35;
$h6-color: $dark;