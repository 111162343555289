/*
 * HEADER
 */

 .c-header {
    position: relative;
    overflow: hidden;
    width: 100%;
 }

 .c-header-inner {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;

    >div {
        overflow: hidden;
    }
}

.c-header-practical,
.c-header-travellers,
.c-header-projects,
.c-header-project,
.c-header-about,
.c-header-services,
.c-header-contact {
     min-height: 100vh;
 }

.c-header-projects,
.c-header-project,
.c-header-about,
.c-header-services,
.c-header-contact {

    .c-nav-main {
        position: absolute;
        top: 0;
   }
}

.c-header-home {
    display: block;
    position: relative;
    //background-image: url('../images/bg_grey.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-color: $white;
    // background-size: 60% 100%;
    z-index: 0;

    &:before {
        content: '';
        z-index: 0;
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        background-image: url('../images/bg_grey.png');
      //background: linear-gradient(90deg, rgba(255,255,255,.7) 0%, rgba(215,215,215,.7) 100%);
    }

    h1 {
        span {
            color: $yellow;
        }
    }

    .o-grid {

        .o-col {

            picture, img {
                margin-top: 1rem;
                max-width: 320px;

                @include media-query (md) {
                    max-width: 480px;
                }

                @include media-query (lg) {
                    max-height: 100%;
                    margin: 0 auto;
                    width: auto;
                }

                @include media-query (xl) {
                    max-width: 640px;
                }

                @include media-query (xxl) {

                }
            }
        }

        @include media-query(lg) {

            height: 100%;

            .o-col {
                height: 100%;
            }
        }
    }

    @include media-query(lg) {
        height: 100vh;
    }
}

.c-header-services {
    height: 550px;

    h5 {
      font-family: 'DinBold';
    }

    @include media-query(md) {
        height: 100vh;
    }
}

.c-header-practical,
.c-header-travellers {
  .c-header-inner {
    .bg-green {
      min-width: 100%;
      min-height: 100%;
      background-image: url('../images/bg_green.png');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;

      .o-block {
        padding: 47px;

        @include media-query(lg) {
          padding: 67px;
        }
      }
    }
  }
}

.c-header-init-no-visible {
    visibility: hidden;


}