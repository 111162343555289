/*
 * IMAGE BG
 */

 .u-img-bg-container {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.u-img-bg {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: block;
    top: 0px;
    left: 0;

    picture, img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.u-img-bg-txt-overlay {
    z-index: 1;
}