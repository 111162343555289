/*
 * GRID
 */

 .o-section-white {
   background-color: $white;
 }

 .o-section-light {
   background-color: $light;
 }

 .o-section-dark {
   background-color: $dark;
 }

 .o-section-shadow {

  .o-grid {
    margin: 0 .5rem;
    box-shadow: 0 6px 12px rgba(black, .1);
  }

 }
