/*
 * MAIN
 */

#main.c-main {
    background-color: $white;
}

// General - Sections

.c-section {
    width: 100%;
    z-index: 1;
}

.c-section-full-height {
    margin-top: 60px;
    margin-bottom: 60px;

    @include media-query(lg) {
        margin-top: 150px;
        margin-bottom: 150px;
    }
}

.c-section-height-bottom {
    margin-top: 60px;
    margin-bottom: 60px;

    @include media-query(lg) {
        margin-top: 0;
        margin-bottom: 150px;
    }
}

.c-section-header {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: inherit;
    min-height: 360px;

    picture, img {
        position: absolute;
        width: 100%;
        height: 120%;
        object-fit: cover;
        object-position: 50% 50%;
    }

}

.c-section-content {
    display: block;
    position: relative;
    width: 100%;
}

.u-position-relative {
    position: relative;
}

// General - Progress scroller

.c-progress-scroller {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 0;

    .c-progress-scroller-bar {
        display: block;
        position: relative;
        width: 100%;
        height: 100vh;

        .c-progress-scroller-bar-bg {
            position: absolute;
            width: 1px;
            height: 100vh;
            left: -.5rem;
            background-color: transparent;

            @include media-query(lg) {
                left: 50%;
            }
        }

        .c-progress-scroller-bar-progress {
            position: absolute;
            width: 1px;
            left: -.5rem;
            background-color: $grey;
            transition: height 1.25s ease;

            @include media-query(lg) {
                left: 50%;
            }
        }
    }
}

.u-z-index-9 {
    z-index: 9;
}

// General - Cuttet image

.c-cuttted-img-clip {
    display: block;
    position: relative;
    clip-path: polygon(15% 0, 100% 0, 100% 85%, 85% 100%, 0 100%, 0 15%);
}

.c-cutted-img {
    // clip-path: polygon(15% 0, 100% 0, 100% 85%, 85% 100%, 0 100%, 0 15%);
    position: relative;
    display: block;

    &:before, &:after {
        content: '';
        display: block;
        border-style: solid;
        position: absolute;
        z-index: 2;
    }

    &:before {
        border-width: 45px 60px 0 0;
        border-color: $white transparent transparent transparent;
        top: 0;
        left: 0;
    }

    &:after {
        border-width: 0 0 45px 60px;
        border-color: transparent transparent $white transparent;
        bottom: 0;
        right: 0;
    }
}

.c-cutted {
    filter: drop-shadow(-1px 4px 4px rgba(255, 50, 0, 1));
}

.c-cutted-inner {
    display: block;
    background-color: $white;
    clip-path: polygon(15% 0, 100% 0, 100% 85%, 85% 100%, 0 100%, 0 15%);
    @include media-query(lg) {
        clip-path: polygon(5% 0, 100% 0, 100% 80%, 95% 100%, 0 100%, 0 20%);
    }
}

.c-cutted-container {
    display: block;
    filter: drop-shadow(-2px 4px 4px rgba(50, 50, 0, .125));

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        background-color: $white;
        clip-path: polygon(15% 0, 100% 0, 100% 85%, 85% 100%, 0 100%, 0 15%);
        @include media-query(lg) {
            clip-path: polygon(15% 0, 100% 0, 100% 85%, 85% 100%, 0 100%, 0 15%);
        }
    }
}

.c-floating {

    @include media-query(lg) {
        animation: float 6s ease-in-out infinite;
    }
}

@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-16px);
	}
	100% {
		transform: translatey(0px);
	}
}

// General - Figure

.c-figure-with-caption {
    position: relative;

    figcaption {
        position: absolute;
        display: block;
        background-color: $yellow;
        color: $white;
        transform: rotate(-90deg);
        transform-origin: bottom right;
    }

    .figcaption-top {
        top: 0;
    }

    .figcaption-bottom {
        bottom: 0;
    }

    .figcaption-left {
        left: 0;
    }

    .figcaption-right {
        right: 0;
    }
}

.c-figure-with-caption.u-img-bg {
    position: absolute;
}

// Vertical btn

button.c-vertical-btn {
    position: absolute;
    top: 35%;
    right: 100%;
    white-space: nowrap ;
    transform: rotate(-90deg);
    transform-origin: top right;
    background-color: transparent;
    text-transform: uppercase;
    font-family: 'DinRegular';
    font-size: .85rem;

    &:active, &:focus {
        outline: none;
        border: none;
    }

    @include media-query(lg) {
        margin-right: -8px;
    }

    @include media-query(xl) {
        margin-right: -20px;
    }

    @include media-query(xxl) {
        margin-right: -32px;
    }
}

button.c-vertical-btn.c-vertical-btn-top {
    top: 0;
    bottom: initial;
}

button.c-vertical-btn.c-vertical-btn-bottom {
    top: initial;
    bottom: 0;
}

.c-arrow {
    border: solid black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    width: .75rem;
    height: .75rem;
}

.c-arrow-right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.c-arrow-left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.c-arrow-up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.c-arrow-down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

// Home - Project

.c-recent-project {
    overflow: hidden;
    position: relative;
}

.c-services-text {

    h3, h4 {
        font-weight: normal !important;
        font-family: 'DinRegular' !important;

        strong {
            font-family: 'DinBlack' !important;
            text-transform: uppercase;
        }
    }
}

// Home - Testimonials

.c-testimonials {
    h2, p, strong {
      color: $white;
    }
}

// Projects

.c-project {
    cursor: pointer;
    display: block;
    position: relative;
    background: $white;
    border-radius: $border-radius;

    picture, img {
        width: 100%;
    }

    figcaption {
        // clip-path: polygon(15% 0, 100% 0, 100% 85%, 85% 100%, 0 100%, 0 15%);
        position: absolute;
        display: flex;
        background-color: rgba(255, 166, 0, .75);
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        transition: opacity .333s ease;
        opacity: 0;

        .u-text-container {
            position: relative;
            width: 100%;

            h3, p {
                color: $white;
            }
        }
    }

    &:hover {

        figcaption {
            opacity: 1;
        }
    }

    a {
        position: absolute;
        display: block;
        white-space: nowrap;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-indent: 200%;
        font-size: 0;
        opacity: 0;
    }
}

// Services

svg.c-service-icon {
    fill: $white;
    width: 100%;
    height: 60px;

    @include media-query(md) {
        height: inherit;
    }
}

// About

.yellow-block {
  width: 100%;
  height: 100%;
  background-color: $yellow;
}

// traveller

.c-traveller {
    height: 100%;

    .o-flex {
      background-color: $light;
    }

    /*
    &:before {
        content: '';
        display: block;
        position: absolute;
        background-color: $light;
        width: 100%;
        height: 75%;
        bottom: 0;
    }

    .c-cutted-img {

        &:after {
            border-color: transparent transparent $light transparent;
        }
    }
    */
}

// Breakouts

.c-breakout-container-first {
    position: relative;
    display: block;
    width: 100vw;
    margin-left: -330px;
    margin-bottom: 300px;

    figure {
        position: relative;
        width: 800px;
        height: 250px;
        object-fit: cover;
    }
}

.c-breakout-container-first {
    position: relative;
    display: block;
    width: 100vw;
    margin-left: -330px;
    margin-bottom: 300px;

    figure {
        position: relative;
        width: 800px;
        height: 250px;
        object-fit: cover;
    }
}

.u-negative-margin-right {

 @include media-query(lg) {
     margin-right: -60px;
 }
}

// Contact

.c-contact-card {
  background-color: $light;
}