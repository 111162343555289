/*
 * FOOTER
 */

 .c-footer {
    background-color: $dark;
    z-index: 10;

    p {
        a {
            text-decoration: none;
        }
    }

    .c-border-right {

        @include media-query(md) {
            border-right: 1px solid $grey;
            height: 100%;
        }
    }
 }

 .c-footer.inverted {

     h1, h2, h3, h4, h5, h6, p , a {
         color: $grey;
     }
 }

.c-footer-logo {
    display: block;

    img {
        height: 35px;
    }

    @include media-query(xl) {
        height: 50px;

        img {
            height: 50px;
        }
    }
}